import React, { useEffect } from 'react'

const IndexPage = () => {
  useEffect(() => {
    window.location.href = 'https://michels-lew.com';
  })
  return (
    <></>
  )
}

export default IndexPage